import type { Route } from "nextjs-routes";

type OGPageType = "Homepage" | "Root page" | "Regular page";

const OG_TYPE_DICT: Record<Route["pathname"], OGPageType> = {
  "/": "Homepage",
  "/txs": "Root page",
  "/tx/[hash]": "Regular page",
  "/blocks": "Root page",
  "/block/[height_or_hash]": "Regular page",
  "/accounts": "Root page",
  "/address/[hash]": "Regular page",
  "/verified-contracts": "Root page",
  "/validators": "Root page",
  "/contract-verification": "Root page",
  "/address/[hash]/contract-verification": "Regular page",
  "/tokens": "Root page",
  "/token/[...slug]": "Regular page",
  // "/token/[hash]/instance/[id]": "Regular page",
  "/stats": "Root page",
  "/api-docs": "Regular page",
  "/graphiql": "Regular page",
  "/auth/profile": "Root page",
  "/auth/profile/watchlist": "Regular page",
  "/auth/profile/api-keys": "Regular page",
  "/auth/profile/custom-abi": "Regular page",
  "/account/public-tags-request": "Regular page",
  "/auth/profile/private-tags": "Regular page",
  "/withdrawals": "Root page",
  "/visualize/sol2uml": "Regular page",
  "/csv-export": "Regular page",
  "/404": "Regular page",

  // service routes, added only to make typescript happy
  "/api/media-type": "Regular page",
  "/api/proxy": "Regular page",
  "/api/csrf": "Regular page",
  "/api/healthz": "Regular page",
  // @ts-ignore
  "/auth/auth0": "Regular page",
  "/auth/unverified-email": "Regular page",
};

export default function getPageOgType(pathname: Route["pathname"]) {
  return OG_TYPE_DICT[pathname];
}
