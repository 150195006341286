import {
  Tab,
  TabIndicator,
  TabIndicatorProps,
  TabList,
  TabListProps,
  TabPanel,
  TabPanels,
  TabProps,
  Tabs,
  TabsProps,
  Text,
} from "@chakra-ui/react";
import { useSetStateQuery } from "lib/router/useSetStateQuery";
import _ from "lodash";
import { RoutedTab } from "./types";

type Props<T extends string, K extends boolean> = {
  /**
   * @description ID of this property when active in router
   */
  id?: string;
  tabs: (RoutedTab<T> & { tabProps?: TabProps })[];
  isSetOnRouter?: K;
  /**
   * Required when isSetOnRouter = false
   */
  value?: T;
  onChange?: (newId: T) => void;
  disableIndicator?: boolean;
  indicatorProps?: TabIndicatorProps;
  tabListProps?: TabListProps;
  cleanupOnTabChange?:
    | {
        keepQueries?: string[];
      }
    | boolean;
  hideOnSingle?: boolean;
} & Partial<Omit<TabsProps, "value" | "onChange" | "index">>;

const TabFloat = <T extends string = string, K extends boolean = true>({
  id,
  tabs,
  value,
  isSetOnRouter = true as K,
  onChange,
  indicatorProps,
  tabListProps,
  disableIndicator,
  cleanupOnTabChange,
  hideOnSingle = false,
  ...props
}: Props<T, K>) => {
  // const router = useRouter();

  const [activeIndex, setTabQuery] = useSetStateQuery(id, [], {
    throttle: 100,
    cleanUp: cleanupOnTabChange
      ? {
          keepQueries: [
            ...((cleanupOnTabChange as any)["keepQueries"] || []),
            "tab",
          ],
        }
      : undefined,

    decode: (nextValue) =>
      id
        ? _.clamp(
            tabs.findIndex(
              (tab) => tab.id === (isSetOnRouter ? nextValue : value),
            ),
            0,
            tabs.length - 1,
          )
        : 0,
  });

  return (
    <Tabs
      position="relative"
      variant="unstyled"
      lazyBehavior="keepMounted"
      scrollBehavior="smooth"
      index={activeIndex || 0}
      onChange={(index) => {
        if (!id) return;
        const newId = String(tabs[index]?.id || undefined);
        if (
          isSetOnRouter &&
          newId !== String(tabs[activeIndex || 0]?.id || undefined)
        ) {
          setTabQuery(newId || "");
        }
        onChange?.(newId as T);
      }}
      display="flex"
      flexDirection="column"
      gap={{ base: 3, lg: 5 }}
      {...props}
    >
      {!(hideOnSingle && tabs.length <= 1) && (
        <TabList
          height="1.875rem"
          position="relative"
          overflowX="auto"
          width="full"
          scrollBehavior="smooth"
          sx={{
            "&::-webkit-scrollbar": {
              display: "none",
            },
            "&": {
              scrollbarWidth: "none",
              msOverflowStyle: "none",
            },
          }}
          {...tabListProps}
        >
          {tabs.map((tab, index) => (
            <Tab
              key={tab.id}
              minWidth="4.5625rem"
              height="1.5rem"
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexShrink={0}
              {...tab.tabProps}
            >
              <Text
                color="neutral.light.8"
                fontSize="1rem"
                fontWeight={400}
                lineHeight="1.5rem"
                whiteSpace="nowrap"
              >
                {typeof tab.title === "function"
                  ? tab.title({ isActive: activeIndex === index })
                  : tab.title}{" "}
                {typeof tab.count === "number" && `(${tab.count})`}
              </Text>
            </Tab>
          ))}
          {disableIndicator !== true && (
            <TabIndicator
              bottom={0}
              height="1px"
              bg="neutral.light.7"
              {...indicatorProps}
            />
          )}
        </TabList>
      )}

      {tabs.some((tab) => tab.component) && (
        <TabPanels padding={0}>
          {tabs.map((tab) => (
            <TabPanel key={tab.id} padding={0}>
              {tab.component}
            </TabPanel>
          ))}
        </TabPanels>
      )}
    </Tabs>
  );
};

export default TabFloat;
