import { Options } from "lib/hooks/useShallow";
import { useWatchState } from "lib/hooks/useWatchState";
import { useRouter } from "next/router";
import getQueryParamString from "./getQueryParamString";
import { useSetQuery } from "./useSetQuery";

export const useSetStateQuery = <
  T extends string = string,
  R extends
    | string
    | number
    | boolean
    | symbol
    | bigint
    | object
    | undefined
    | null
    | Date = T,
  E extends string | number | boolean | symbol | bigint | object | Date = T,
>(
  queryName: any,
  watch?: any[],
  options?: Options & {
    cleanUp?: { keepQueries?: string[] };
    removeQueries?: string[];
    decode?: (value: T | "" | undefined) => R;
    encode?: (value: E) => T;
  },
): [
  "" | R,
  (nextValue: E | "" | ((e: R | "") => E | ""), defer?: boolean) => void,
] => {
  const router = useRouter();
  const setQuery = useSetQuery(queryName, options);
  const [value, setValue] = useWatchState(
    getQueryParamString<T>(router.query[queryName]) as "" | T,
    watch,
    {
      ...options,
      transform: options?.decode,
    },
  );

  return [
    value,
    (nextValue) => {
      const encoded = options?.encode
        ? options.encode(
            typeof nextValue === "function" ? nextValue(value) : nextValue,
          )
        : typeof nextValue === "function"
          ? nextValue(value)
          : nextValue;
      setValue(encoded, {
        preset: () => {
          setQuery(encoded);
        },
      });
    },
  ];
};
