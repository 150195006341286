import { chakra, HStack, Skeleton, StackProps, Text } from "@chakra-ui/react";
import { usePromotion } from "lib/zustand/promotion";
import { memo, useMemo } from "react";
import IconSvg from "../shared/IconSvg";
import LinkExternal from "../shared/LinkExternal";

type Props = {
  //
} & StackProps;

const Promotion = ({ ...props }: Props) => {
  const { brand, primary, push, url, logo } = usePromotion(
    (store) => store.promotion,
  );

  const mixedDescription = useMemo(
    () => ["-", ...primary.split(" ")],
    [primary],
  );

  return (
    <HStack
      spacing={0}
      {...props}
      alignItems="center"
      userSelect="none"
      flexWrap="wrap" /* Để tự động xuống dòng */
    >
      <Text
        as="span"
        textStyle="87500"
        whiteSpace="nowrap"
        color="neutral.light.6"
        flexShrink={0} /* Giữ cố định không bị thu nhỏ */
        mr={2}
      >
        Sponsored:
      </Text>

      <IconSvg
        flexShrink={0}
        boxSize={5}
        src={logo}
        mr={2}
        rounded="full"
        overflow="hidden"
        optimizationImageProps={{ fallback: <Skeleton boxSize="full" /> }}
      />

      <Text
        as="span"
        whiteSpace="nowrap"
        textStyle="875700"
        flexShrink={0}
        mr={1}
      >
        {brand}
      </Text>

      <>
        {mixedDescription.map((word, index) => (
          <chakra.span
            key={index}
            flexShrink={0}
            mr={1}
            textStyle="875"
            color="neutral.light.6"
          >
            {word}
            {index === mixedDescription.length - 1 ? "." : ""}
          </chakra.span>
        ))}
      </>

      <LinkExternal href={url} textStyle="87500" noIcon>
        {push}
      </LinkExternal>
    </HStack>
  );
};

export default memo(Promotion);
